<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Seja um revendedor</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- formulario area start -->
      <section class="formulario__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2 container-formulario">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="section__head mb-30">
                           <div class="w-100 section__title mb-1">
                              <h3 class="text-center">Formulário</h3>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1">
                        <logoMarcas :classes="'brand__logo__area pt-10 pb-30'" :view="'SejaRevendedor'" />
                     </div>
                     <div class="col-12 px-1 mb-30">
                        <p class="text-center" v-html="sourceRevendedor.descricao"></p>
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>CNPJ *</label>
                        <div class="input-group">
                           <input type="text" class="form-control" maxlength="200" v-model="formulario.cnpj" @keyup.enter="buscarCNPJ">
                           <a href="javascript:;" class="input-group-text font-13" @click="buscarCNPJ">
                              <i class="fas fa-search"></i>
                           </a>
                        </div>
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label class="limitador">Inscrição estadual</label>
                        <input type="text" class="form-control" v-model="formulario.ie" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Razão social *</label>
                        <input type="text" class="form-control" v-model="formulario.razaoSocial" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Nome fantasia *</label>
                        <input type="text" class="form-control" v-model="formulario.nomeFantasia" maxlength="200" />
                     </div>
                     <!-- <div class="col-12 px-1 mb-10">
                        <label>Endereço p/ correspondência *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.rua" maxlength="200" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Complemento</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.complemento" maxlength="100" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>Nº</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.numero" maxlength="15" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Bairro</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.bairro" maxlength="50" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>CEP *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.cep" maxlength="10" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Cidade *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.cidade" maxlength="100" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>UF *</label>
                        <input type="text" class="form-control" v-model="formulario.endereco.estado" maxlength="2" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Endereço comercial/compras</label>
                        <input type="text" class="form-control" v-model="formulario.enderecoComercial.rua" maxlength="200" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Complemento</label>
                        <input type="text" class="form-control" v-model="formulario.enderecoComercial.complemento" maxlength="100" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>Nº</label>
                        <input type="text" class="form-control" v-model="formulario.enderecoComercial.numero" maxlength="15" />
                     </div>
                     <div class="col-7 px-1 mb-10">
                        <label>Bairro</label>
                        <input type="text" class="form-control" v-model="formulario.enderecoComercial.bairro" maxlength="50" />
                     </div>
                     <div class="col-5 px-1 mb-10">
                        <label>CEP</label>
                        <input type="text" class="form-control" v-model="formulario.enderecoComercial.cep" maxlength="10" />
                     </div> -->
                     <div class="col-12 px-1 mb-10">
                        <label>Suframa</label>
                        <input type="text" class="form-control" v-model="formulario.suframa" maxlength="20" />
                     </div>
                     <div class="col-6 px-1 mb-10">
                        <label>Telefone 1 *</label>
                        <input type="text" class="form-control" v-model="formulario.telefone1" maxlength="50" />
                     </div>
                     <div class="col-6 px-1 mb-10">
                        <label>Telefone 2</label>
                        <input type="text" class="form-control" v-model="formulario.telefone2" maxlength="50" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Ramo de negócio *</label>
                        <input type="text" class="form-control" v-model="formulario.ramo" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Email comercial/compras *</label>
                        <input type="email" class="form-control" v-model="formulario.emailComercial" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-10">
                        <label>Email financeiro</label>
                        <input type="email" class="form-control" v-model="formulario.emailFinanceiro" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 mb-20">
                        <label>Email XML (Nota fiscal)</label>
                        <input type="email" class="form-control" v-model="formulario.emailXml" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 text-center">
                        <button class="t-y-btn t-y-btn-2" @click="cadastrar">Enviar formulário <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import logoMarcas from '@/components/CarouselLogoMarcas.vue'

export default {
	name: 'Seja_Revendedor',
   data: function() {
		return {
			formulario: {'razaoSocial': '', 'nomeFantasia': '', 'cnpj': '', 'ie': '', 'suframa': '', 'telefone1': '', 'telefone2': '', 'ramo': '', 'emailComercial': '', 
            'emailFinanceiro': '', 'emailXml': '', 'endereco': {'rua': '', 'numero': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': '', 'complemento': ''}, 
            'enderecoComercial': {'rua': '', 'numero': '', 'bairro': '', 'cep': '', 'complemento': ''}}
		}
	},
   computed: {
		... mapState({
         sourceRevendedor: state => state.source.sejaRevendedor,
			urlRest: state => state.urlRest
		})
	},
   components: {
      logoMarcas
   },
   methods: {
      buscarCNPJ : function () {
         if (String(this.formulario.cnpj).trim().length < 5) return;

         this.$store.dispatch('buscarCNPJ', this.formulario.cnpj).then((data) => {
            this.formulario = {
               'razaoSocial': data.razao_social,
               'nomeFantasia': data.estabelecimento.nome_fantasia,
               'cnpj': data.estabelecimento.cnpj,
               'ie': data.estabelecimento.inscricoes_estaduais.filter(i => i.ativo).length > 0 ? data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual : '',
               'suframa': '',
               'telefone1': (data.estabelecimento.ddd1 != null ? data.estabelecimento.ddd1 : '') + (data.estabelecimento.telefone1 != null ? data.estabelecimento.telefone1 : ''),
               'telefone2': (data.estabelecimento.ddd2 != null ? data.estabelecimento.ddd2 : '') + (data.estabelecimento.telefone2 != null ? data.estabelecimento.telefone2 : ''),
               'ramo': data.estabelecimento.atividade_principal.descricao,
               'emailComercial': data.estabelecimento.email, 
               'emailFinanceiro': '', 
               'emailXml': '', 
               'endereco': {
                  'rua': data.estabelecimento.logradouro, 
                  'numero': data.estabelecimento.numero, 
                  'bairro': data.estabelecimento.bairro, 
                  'cep': data.estabelecimento.cep, 
                  'cidade': data.estabelecimento.cidade.nome, 
                  'estado': data.estabelecimento.estado.sigla, 
                  'complemento': data.estabelecimento.complemento
               }, 
               'enderecoComercial': {'rua': '', 'numero': '', 'bairro': '', 'cep': '', 'complemento': ''}
            }
         })
      },
      cadastrar : function () {
         if (this.formulario.razaoSocial == null || String(this.formulario.razaoSocial).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Razão social inválida!'
            });

            return
         } else if (this.formulario.nomeFantasia == null || String(this.formulario.nomeFantasia).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome fantasia inválido!'
            });

            return
         } else if (this.formulario.cnpj == null || String(this.formulario.cnpj).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CNPJ inválido!'
            });

            return
         } else if (this.formulario.telefone1 == null || String(this.formulario.telefone1).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Telefone inválido!'
            });

            return
         } else if (this.formulario.endereco.rua == null || String(this.formulario.endereco.rua).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Rua inválida!'
            });

            return
         } else if (this.formulario.endereco.cidade == null || String(this.formulario.endereco.cidade).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Cidade inválida!'
            });

            return
         } else if (this.formulario.endereco.estado == null || String(this.formulario.endereco.estado).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Estado inválido!'
            });

            return
         } else if (this.formulario.endereco.cep == null || String(this.formulario.endereco.cep).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CEP inválido!'
            });

            return
         } else if (this.formulario.ramo == null || String(this.formulario.ramo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Ramo inválido!'
            });

            return
         } else if (this.formulario.emailComercial == null || String(this.formulario.emailComercial).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email comercial inválido!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'site/cadastrarRevendedor',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.formulario

         }).then(() => {
            this.formulario = {'razaoSocial': '', 'nomeFantasia': '', 'cnpj': '', 'ie': '', 'suframa': '', 'telefone1': '', 'telefone2': '', 'ramo': '', 'emailComercial': '', 
               'emailFinanceiro': '', 'emailXml': '', 'endereco': {'rua': '', 'numero': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': '', 'complemento': ''}, 
               'enderecoComercial': {'rua': '', 'numero': '', 'bairro': '', 'cep': '', 'complemento': ''}}

            Swal.fire({
               icon: 'success',
               title: 'Formulário enviado!'

            }).then(() => {
               router.push('/')
            });
         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   }
}

</script>

<style scoped>

.container-formulario input {
	padding: 8px 12px;
   margin: 0;
   height: auto;
}

.container-formulario label {
   color: #222;
   font-size: 11.5px;
}

</style>
